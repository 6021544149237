var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "container",
    attrs: {
      "else": ""
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "bg-lightgray",
    attrs: {
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "text-grey"
  }, [_c('div', {
    staticClass: "mt-3 mx-3"
  }, [_c('h5', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Filters")]), _c('hr')]), _c('div', [_c('h5', {
    staticClass: "font-weight-bold ml-3"
  }, [_vm._v("Category")]), _vm._l(this.isCategoryList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "custom-control custom-checkbox collection-filter-checkbox mx-3"
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "type": "checkbox",
        "id": item.name
      },
      domProps: {
        "value": _vm.itemName(item.name)
      },
      on: {
        "change": function change($event) {
          return _vm.onChangeCategory($event);
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": item.name
      }
    }), _vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2), _c('hr'), _c('div', [_c('h5', {
    staticClass: "font-weight-bold ml-3"
  }, [_vm._v("Product Types")]), _vm._l(_vm.productTypeList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "custom-control custom-checkbox collection-filter-checkbox mx-3"
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "type": "checkbox",
        "id": item.name
      },
      domProps: {
        "value": item.name
      },
      on: {
        "change": function change($event) {
          return _vm.onChangeProductType($event);
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": item.name
      }
    }), _vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2), _c('hr'), _c('div', [_c('h5', {
    staticClass: "font-weight-bold ml-3"
  }, [_vm._v("Size")]), _vm._l(_vm.sizes, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "custom-control custom-checkbox collection-filter-checkbox mx-3"
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "type": "checkbox",
        "id": item.text
      },
      domProps: {
        "value": item.value
      },
      on: {
        "change": function change($event) {
          return _vm.onChangeSize($event);
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": item.text
      }
    }), _vm._v(" " + _vm._s(item.text) + " ")]);
  })], 2), _c('hr'), _c('div', [_c('h5', {
    staticClass: "font-weight-bold ml-3"
  }, [_vm._v("Colours")]), _vm._l(_vm.color, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "custom-control custom-checkbox collection-filter-checkbox mx-3"
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "type": "checkbox",
        "id": item.text
      },
      domProps: {
        "value": item.value
      },
      on: {
        "change": function change($event) {
          return _vm.onChangeColour($event);
        }
      }
    }), _c('span', {
      class: "".concat(item.value)
    }), _c('label', {
      staticClass: "color-code custom-control-label",
      attrs: {
        "for": item.text
      }
    }), _vm._v(" " + _vm._s(item.text) + " ")]);
  })], 2)])]), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('h5', {
    staticClass: "ml-4"
  }, [_vm._v(" " + _vm._s(_vm.selected != "" ? _vm.selected : this.$route.params.data) + " ")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "3"
    }
  }, [_c('div', [_c('b-form-select', {
    staticClass: "form-select filter-select form-control border",
    attrs: {
      "options": _vm.options
    },
    on: {
      "input": function input($event) {
        return _vm.onChangeSort($event);
      }
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)])], 1)], 1)], 1), _c('div', [_vm.$options.components['advertisement-loading'] ? [_vm.isLoading ? _c('advertisement-loading') : _vm._e()] : _vm._e(), _vm.categoryListItems.length > 0 ? _c('div', [_c('sections-slider', {
    staticClass: "suggested-slider",
    attrs: {
      "list": _vm.categoryListItems,
      "hasBackground": true,
      "hasButton": false,
      "showTitle": true,
      "withoutSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-fashion-item', {
          staticClass: "suggester-div w-100",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }], null, false, 2732896317)
  })], 1) : _c('div', {
    staticClass: "text-center no-item"
  }, [_vm._v("No items available!")])], 2)], 1)], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }