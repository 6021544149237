<template>
  <div>
    <div class="container" else>
      <div class="container">
        <b-row>
          <b-col class="bg-lightgray" md="3">
            <div class="text-grey">
              <div class="mt-3 mx-3">
                <h5 class="font-weight-bold">Filters</h5>
                <hr />
              </div>
              <div>
                <h5 class="font-weight-bold ml-3">Category</h5>
                <div
                  class="custom-control custom-checkbox collection-filter-checkbox mx-3"
                  v-for="(item, index) in this.isCategoryList"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="item.name"
                    :value="itemName(item.name)"
                    @change="onChangeCategory($event)"
                  />

                  <label
                    class="custom-control-label"
                    v-bind:for="item.name"
                  ></label>
                  {{ item.name }}
                </div>
              </div>
              <hr />
              <div>
                <h5 class="font-weight-bold ml-3">Product Types</h5>
                <div
                  class="custom-control custom-checkbox collection-filter-checkbox mx-3"
                  v-for="(item, index) in productTypeList"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="item.name"
                    :value="item.name"
                    @change="onChangeProductType($event)"
                  />
                  <!-- @change="appliedFilter(keyword.key, item.key)" -->
                  <label
                    class="custom-control-label"
                    v-bind:for="item.name"
                  ></label>
                  {{ item.name }}
                </div>
              </div>
              <hr />
              <div>
                <h5 class="font-weight-bold ml-3">Size</h5>
                <div
                  class="custom-control custom-checkbox collection-filter-checkbox mx-3"
                  v-for="(item, index) in sizes"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="item.text"
                    :value="item.value"
                    @change="onChangeSize($event)"
                  />
                  <!-- @change="appliedFilter(keyword.key, item.key)" -->
                  <label
                    class="custom-control-label"
                    v-bind:for="item.text"
                  ></label>
                  {{ item.text }}
                </div>
              </div>
              <hr />
              <div>
                <h5 class="font-weight-bold ml-3">Colours</h5>
                <div
                  class="custom-control custom-checkbox collection-filter-checkbox mx-3"
                  v-for="(item, index) in color"
                  :key="index"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="item.text"
                    :value="item.value"
                    @change="onChangeColour($event)"
                  />
                  <!-- @change="appliedFilter(keyword.key, item.key)" -->
                  <span :class="`${item.value}`"></span>
                  <label
                    class="color-code custom-control-label"
                    v-bind:for="item.text"
                  ></label>
                  {{ item.text }}
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="9">
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="9">
                    <h5 class="ml-4">
                      {{ selected != "" ? selected : this.$route.params.data }}
                    </h5></b-col
                  >
                  <b-col md="3" class="text-right">
                    <div>
                      <b-form-select
                        class="form-select filter-select form-control border"
                        v-model="selected"
                        :options="options"
                        @input="onChangeSort($event)"
                      ></b-form-select>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <div>
              <template v-if="$options.components['advertisement-loading']">
                <advertisement-loading v-if="isLoading" />
              </template>
              <div v-if="categoryListItems.length > 0">
                <sections-slider
                  :list="categoryListItems"
                  :hasBackground="true"
                  :hasButton="false"
                  :showTitle="true"
                  :withoutSlider="true"
                  class="suggested-slider"
                >
                  <template slot="default" slot-scope="{ listItem }">
                    <div class="item-box">
                      <items-fashion-item
                        :itemNoBg="true"
                        :product="listItem"
                        :incrementDecrementKey="true"
                        class="suggester-div w-100"
                      >
                      </items-fashion-item>
                    </div>
                  </template>
                </sections-slider>
              </div>
              <div v-else class="text-center no-item">No items available!</div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "category-listing",
  data() {
    return {
      theme: {},
      marketName: "",
      selectedColorName: "",
      selectedSizeName: "",
      selectedProductType: "",
      selectedCategoryname: "",
      selected: null,
      isLoading: false,
      options: [
        { value: null, text: "Sorting Items" },
        { value: "a-z", text: "Alphabetically, A-Z" },
        { value: "z-a", text: "Alphabetically, Z-A" },
        { value: "low", text: "price, low to high" },
        { value: "high", text: "price, high to low" },
      ],

      sizes: [
        { value: "S", text: "Small (S)" },
        { value: "M", text: "Medium (M)" },
        { value: "L", text: "Large (L)" },
        { value: "XL", text: "Extra Large (XL)" },
        { value: "XXL", text: "Double XL(XXL)" },
      ],
      color: [
        { value: "black", text: "Black" },
        { value: "pink", text: "Pink" },
        { value: "red", text: "Red" },
        { value: "white", text: "White" },
        { value: "green", text: "Green" },
        { value: "yellow", text: "Yellow" },
        { value: "blue", text: "Blue" },
        { value: "purple", text: "Purple" },
        { value: "orange", text: "Orange" },
      ],
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  computed: {
    ...mapState({
      currentPosition: (state) => state.location.currentPosition,
      isCategoryList: (state) => state.fashionMarket.isCategoryList,
      marketList: (state) => state.fashionMarket.marketList,
      productTypeList: (state) => state.fashionMarket.productTypeList,
      categoryListItems: (state) => state.fashionMarket.categoryListItems,
    }),
  },

  methods: {
    itemName(name) {
      if (name == "Men's Fashion") {
        return "Men's";
      } else if (name == "Women Fashion") {
        return "Women";
      } else {
        return name;
      }
    },
    onChangeSort(event) {
      this.$store.dispatch("fashionMarket/sortProducts", event);
    },
    onChangeCategory(event) {
      console.log(event, "event");
      this.selectedCategoryname = event.target.value;
      this.appliedFilter();
    },
    onChangeProductType(event) {
      this.selectedProductType = event.target.value;
      this.appliedFilter();
    },
    onChangeSize(event) {
      this.selectedSizeName = event.target.value;
      this.appliedFilter();
    },
    onChangeColour(event) {
      this.selectedColorName = event.target.value;
      this.appliedFilter();
    },
    async appliedFilter() {
      const payload = {
        location: {
          lat: this.currentPosition.Lat,
          lon: this.currentPosition.Lng,
          radius: 1000,
        },
        category: this.selectedCategoryname,
        size: this.selectedSizeName,
        productType: this.selectedProductType,
        color: this.selectedColorName,
        pagination: {
          itemsPerPage: 25,
          page: 1,
        },
        market: "Cloths & Fashion",
        // filterPayload: {
        //   category: this.selectedCategoryname,
        //   size: this.selectedSizeName,
        //   productType: this.selectedProductType,
        //   price: {
        //     minPrice: lowerPrice != '' ? lowerPrice : 1,
        //     maxPrice: higherPrice != '' ? higherPrice : 99999,
        //   },
        //color: this.selectedColorName,
        //},
      };
      await this.$store.dispatch("fashionMarket/fetchSearchResult", payload);
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("fashionMarket/fetchAllMarket");
    const marketId = this.marketList.filter((e) => {
      if (e.name == "Clothes & Fashion") {
        this.theme = e.theme;
        this.$store.commit("theme/updateTheme", this.theme);
        if (e.name == "Clothes & Fashion") {
          this.marketName = "Fashion";
        }
        this.$store.commit("theme/updateName", this.marketName);
        return e._id;
      }
    });
    await this.$store.dispatch(
      "fashionMarket/fetchfashionMarketCategory",
      marketId[0]._id
    );
    const id = this.isCategoryList.filter((e) => {
      if (e.name === this.$route.params.data) {
        return e._id;
      }
    });
    await this.$store.dispatch("fashionMarket/fetchProductType", id);
    const payload = {
      location: {
        lat: this.currentPosition.Lat,
        lon: this.currentPosition.Lng,
        radius: 1000,
      },
      category:
        this.$route.params.data == "Men's Fashion"
          ? "Men's"
          : "" || this.$route.params.data == "Women Fashion"
          ? "Women"
          : "" || this.$route.params.data,
      pagination: {
        itemsPerPage: 25,
        page: 1,
      },
      market: "Clothes & Fashion",
    };
    await this.$store.dispatch("fashionMarket/fetchSpecificCategory", payload);
    this.isLoading = false;
  },
};
</script>
<style scoped>
.show-variant {
  position: relative;
}
.stores-slider .item-box {
  margin: 0 12px;
  width: 208px;
  /* background: #ffffff; */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.suggested-slider .item-box {
  margin: 0 12px;
  width: 266px;
  display: block;
}
.suggester-div {
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
}

.suggested-slider .suggester-div:hover {
  border: 1px solid #272727;
  box-shadow: 0px 8px 15px 0px rgb(0 0 0 / 10%);
}

.marketitem-slider .item-box {
  margin: 0 12px;
  width: 208px;
  display: block;
}
.bg-colors {
  background: #f8f8f8;
}
@media (max-width: 768px) {
  .v-slide-group__next,
  .v-slide-group__prev {
    display: none !important;
  }

  .marketitem-slider .item-box {
    width: 266px;
  }

  .stores-slider .item-box {
    margin: 0 12px;
    width: 136px;
  }
}
.slider-contaner-2 {
  width: 80%;
}
.slider-contaner-1 {
  width: 100%;
}
.variant-span {
  position: absolute;
  background-color: #ffef40;
  padding: 4px 10px 4px 25px;
  font-size: 13px;
  color: #272727;
  top: 120px;
  right: 0;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20% 50%);
  font-weight: 500;
}
.item-no-bg-discountbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 30px;
  background-color: var(--primary);
  position: absolute;
  right: 0;
  top: 16px;
}
.item-no-bg-discountbox p::before {
  color: white;
  height: 14px;
}
.item-no-bg-discountbox p {
  color: white;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0;
  font-weight: 700;
}

.bg-lightgray {
  background: #f8f8f8;
}
.category {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 39px;
  text-transform: capitalize;
  color: #000000;
  margin-left: 78px;
}

.text-icon::before {
  color: var(--primary);
  width: 10px;
  position: relative;
  right: 3px;
}
.pink {
  position: absolute;
  left: 96px;
  top: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fb807f;
}
.green {
  position: absolute;
  left: 96px;
  top: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #419a23;
}
.yellow {
  position: absolute;
  left: 96px;
  top: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffed49;
}
.blue {
  position: absolute;
  left: 96px;
  top: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #0077e9;
}
.purple {
  position: absolute;
  left: 96px;
  top: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fb807f;
}
.red {
  position: absolute;
  left: 96px;
  top: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ff0200;
}
.white {
  position: absolute;
  left: 96px;
  top: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffff;
}
.orange {
  position: absolute;
  left: 96px;
  top: 4px;
  width: 16px;
  height: 16px;

  border-radius: 50%;
  background: #fe7b08;
}
.black {
  position: absolute;
  left: 96px;
  top: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #000000;
}
.item-price::before {
  color: #119744;
}
.filter {
  font-style: normal;
  margin-top: 20px;
}

.item-name {
  width: 150px;
  text-align: center;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 0px;
  position: relative;
  text-align: center;
  color: #000000;
}
.item-image {
  height: 148px;
  width: 183px;
}
.item {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  margin-left: 10px;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.item-price {
  font-style: normal;
  font-weight: 200;
  font-size: 10px;
  line-height: 15px;
  text-decoration-line: line-through;
  text-transform: capitalize;
  color: #000000;
}
.discount {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-transform: capitalize;
  color: #000000;
}

.stock {
  z-index: 9999;
}
.no-item {
  font-weight: 600;
  font-size: 24px;
  padding: 80px;
}
.stock svg {
  overflow: inherit;
  position: absolute;
  z-index: 1;
  right: 0px;
  animation: rotation 3s infinite linear;
  -webkit-transition: all 3s ease;
  -moz-transition: all 3s ease;
  -o-transition: all 3s ease;
  -ms-transition: all 3s ease;
  transition: all 3s ease;
}
</style>
